<template>
  <div class="schedule-table">
    <loader v-if="isLoading" />
    <template v-else>
      <div class="schedule-table__block __head" :class="{ __mobile: isMobile }">
        <action-cell v-if="!isMobile" />
        <div class="schedule-table__table">
          <template v-for="item in visibleHours">
            <div v-if="item" class="schedule-table__cell" :key="item">
              <div class="schedule-table__item  __header">{{ item }}</div>
            </div>
          </template>
        </div>
      </div>
      <div
        v-for="(date, dateIndex) in workShiftList"
        class="schedule-table__block"
        :class="{ __mobile: isMobile }"
        :key="dateIndex && date?.date"
      >
        <div class="schedule-table__date-block">
          <action-cell :date="date" @openSettings="handleClickDateCell" />
          <!-- TODO - модалка тегов: вернуть !isMobile когда будем реализовывать модалку по настройки строк и тегов -->
          <div v-if="false" class="schedule-table__place-block">
            <action-cell v-for="(row, workShiftIndex) in date.timeData" :key="workShiftIndex" :tag="row?.tag" />
          </div>
        </div>
        <div class="schedule-table__table">
          <template v-for="(row, rowIndex) in date.timeData">
            <template v-for="(workShift, workShiftIndex) in row.shiftList">
              <default-cell
                :has-part="hasPart(row, workShiftIndex)"
                :work-shift="workShift"
                :indexes="{ rowIndex, workShiftIndex }"
                :key="workShift.id"
                :can-add-work-shift="canAddWorkShift"
                @handleClickCell="handleClickCell"
                @handleRightClickCell="currentWorkShift => $emit('handleRightClickCell', currentWorkShift)"
              />
            </template>
          </template>
        </div>
      </div>
    </template>
    <shift-tags-setup-modal @updateTags="v => $emit('updateTags', v)" @handleCloseModal="handleCloseModal" />
    <shift-edit-modal @handleCloseModal="handleCloseModal" @updateShift="$emit('updateShift')" />
    <schedule-modal />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vue2-helpers/vuex";
import { offsetApply, timeFormat } from "@/components-js/timeOffset";
import { formattedDate } from "@/components-js/dateFormat";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import { HOURS_VISUAL } from "@/config/settings";
import ActionCell from "./components/ActionCell";
import DefaultCell from "./components/DefaultCell";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";
import ShiftEditModal from "@/views/SchedulePage/components/ShiftEditModal/ShiftEditModal.vue";
import ShiftTagsSetupModal from "@/views/SchedulePage/components/ShiftTagsSetupModal/ShiftTagsSetupModal.vue";
import { useRouter } from "vue2-helpers/vue-router";
import ScheduleModal from "@/components/ScheduleModal";
import { TURN_FREE_VAL } from "@/components-js/turns";
import Loader from "@/components/Loader.vue";

const emits = defineEmits(["addNewRow"]);

const props = defineProps({
  /**
   * @type {WorkShiftList}
   * */
  workShiftList: Array,
  canAddWorkShift: Boolean,
  isLoading: Boolean,
});

const store = useStore();
const router = useRouter();
const visibleHours = ref(HOURS_VISUAL);
const coordinatesSelectedCell = ref({ start: null, end: null });
const firstSelectedCell = ref(null);
const hiddenCells = ref([]);
const user = computed(() => store.state.user);

const isMobile = computed(() => {
  return store?.state?.breakPoint === DEVICE_TYPES.MOBILE;
});

const getDateWorkShift = date => {
  return formattedDate(new Date(date));
};

const formatDayNumber = item => {
  return timeFormat(offsetApply(item), "short");
};

const hasPart = (row, currentIndex) => {
  const prevWorkShift = row.shiftList[currentIndex - 1];
  const nextWorkShift = row.shiftList[currentIndex + 1];
  const middleIndex = currentIndex > 0 || currentIndex < row.length;

  const prevSize =
    !!prevWorkShift && prevWorkShift.minuteEnd > 0
      ? (1 - (prevWorkShift.minuteEnd - prevWorkShift.minuteBegin) / 60) * 4
      : 0;
  const nextSize =
    !!nextWorkShift && nextWorkShift.minuteBegin > 0
      ? (1 - (nextWorkShift.minuteEnd - nextWorkShift.minuteBegin) / 60) * 4
      : 0;

  return { prevSize, nextSize };
};

const handleCloseModal = success => {
  const workShift = coordinatesSelectedCell.value.start?.item;
  coordinatesSelectedCell.value.start = null;
  coordinatesSelectedCell.value.end = null;

  if (firstSelectedCell.value) {
    firstSelectedCell.value.classList.remove("__selected");
    firstSelectedCell.value.parentElement.style.gridColumn = `span ${(workShift.hourEnd -
      workShift.hourBegin +
      (workShift.minuteEnd - workShift.minuteBegin) / 60) *
      4}`;
    firstSelectedCell.value = null;
  }

  if (!success) hiddenCells.value.forEach(elem => (elem.style.display = "block"));
  hiddenCells.value = [];
};

const handleClickDateCell = date => {
  emits("addNewRow", date);

  //TODO - модалка тегов: делаем пока просто добавление строки, ниже код открывающий модалку с настройкой строк и тегов
  //emitter.emit(EVENTS.SHOW_MODAL, { name: "shift-tags-setup", route: "schedule-tags-settings", date });
};

const handleClickCell = (e, item, date, rowIndex, colIndex) => {
  const openSchedule = isEdited => {
    emitter.emit(EVENTS.SHOW_MODAL, {
      name: "shift-edit",
      route: "schedule-shift-settings",
      workShift: {
        ...item,
        employee: item.employee ?? {},
        job: item.job ?? {},
        turnOrder: rowIndex,
        // hourBegin: isEdited ? item.hourBegin : coordinatesSelectedCell.value.start?.item.hourBegin,
        ...(isEdited
          ? {}
          : {
              hourBegin: coordinatesSelectedCell.value.start?.item.hourBegin,
              hourEnd: coordinatesSelectedCell.value.end?.item.hourEnd,
              minuteBegin: coordinatesSelectedCell.value.start?.item.minuteBegin,
              minuteEnd: coordinatesSelectedCell.value.end?.item.minuteEnd,
            }),
      },
    });
  };

  const handleStartSelect = () => {
    coordinatesSelectedCell.value.start = {
      date,
      hour: item.hourBegin,
      row: rowIndex,
      col: colIndex,
      item,
    };
    firstSelectedCell.value = e.target;
    firstSelectedCell.value.classList.add("__selected");
  };

  const handleEndSelect = () => {
    coordinatesSelectedCell.value.end = {
      date,
      hour: item.hourEnd,
      row: rowIndex,
      col: colIndex,
      item,
    };

    firstSelectedCell.value.parentElement.style.gridColumn = `span ${(coordinatesSelectedCell.value.end?.item.hourEnd -
      coordinatesSelectedCell.value.start?.item.hourBegin +
      (coordinatesSelectedCell.value.end?.item.minuteEnd - coordinatesSelectedCell.value.start?.item.minuteBegin) /
        60) *
      4}`;
  };

  const clearCoordinates = () => {
    const workShift = coordinatesSelectedCell.value.start?.item;
    coordinatesSelectedCell.value.start = null;
    coordinatesSelectedCell.value.end = null;

    if (firstSelectedCell.value) {
      firstSelectedCell.value.classList.remove("__selected");
      firstSelectedCell.value.parentElement.style.gridColumn = `span ${(workShift.hourEnd -
        workShift.hourBegin +
        (workShift.minuteEnd - workShift.minuteBegin) / 60) *
        4}`;
      firstSelectedCell.value = null;
    }

    hiddenCells.value.forEach(elem => (elem.style.display = "block"));
    hiddenCells.value = [];
  };

  const hideIntermediateCells = () => {
    let elem = firstSelectedCell.value.parentElement;
    while (elem != e.target.parentElement) {
      if (coordinatesSelectedCell.value.start?.col > coordinatesSelectedCell.value.end?.col) {
        elem = elem.previousElementSibling;
      } else {
        elem = elem.nextElementSibling;
      }

      if (elem.firstElementChild.classList.contains("__has-work-shift")) {
        clearCoordinates();
        return;
      }

      elem.style.display = "none";
      hiddenCells.value.push(elem);
    }
    return true;
  };

  if (props.canAddWorkShift) {
    if (item.id) {
      clearCoordinates();
      openSchedule(true);
    } else {
      if (!coordinatesSelectedCell.value.start) {
        handleStartSelect();
      } else {
        if (!coordinatesSelectedCell.value.end) {
          if (
            coordinatesSelectedCell.value.start.date !== date ||
            coordinatesSelectedCell.value.start.row !== rowIndex
          ) {
            clearCoordinates();
          } else {
            handleEndSelect();
            if (coordinatesSelectedCell.value.start.item.hourBegin > coordinatesSelectedCell.value.end.item.hourBegin) {
              clearCoordinates();
              return;
            }
            hideIntermediateCells() && openSchedule();
          }
        } else {
          clearCoordinates();
        }
      }
      if (!coordinatesSelectedCell.value.start) {
        handleStartSelect();
      }
    }
  } else {
    if (!!item.status && item.status !== TURN_FREE_VAL) {
      emitter.emit(EVENTS.SHOW_MODAL, {
        name: "schedule-modal",
        workShift: item,
        route: "personal-schedule-modal",
        currentPoint: { id: item.pointId },
        currentDate: date,
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";
.schedule-table {
  //position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--sp-2);
  position: relative;

  &__block {
    display: grid;
    /** TODO - модалка тегов: вернуть grid-template-columns: minmax(162px, 1.1fr) 9fr; **/
    grid-template-columns: 77px 9fr;
    grid-auto-flow: row;
    gap: 8px;
    width: 100%;

    &.__head {
      position: sticky;
      top: 122px;
      padding-bottom: var(--sp-2);
      background: white;
    }

    &:nth-child(2) {
      &:not(.__head) {
        margin-top: -8px;
      }
    }

    &.__mobile {
      //display: flex;
      //flex-direction: column;
      //display: grid;
      //grid-auto-flow: column;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(68, 1fr);
    grid-auto-flow: row;
    gap: var(--sp-2);
  }

  &__cell {
    grid-column: span 4;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background: var(--Primary-light-bg, #eef5fc);

    &.__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__item {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    cursor: pointer;
    //border-radius: 5px;
    //background: var(--Primary-light-bg, #eef5fc);

    &.__date {
      height: 100%;
      justify-content: flex-start;
      padding: var(--sp-2) 0;
    }

    &.__header {
      background: var(--Secondary-Bg, #ffd596);
      justify-content: center;
      cursor: default;
      @include add-font-face("body-1");
    }

    &.__has-work-shift {
      background: var(--Primary-bg, #dbedff);
    }

    &.__empty {
      background: var(--Red-Light-bg, #ffe7ef);
      justify-content: center;
      .schedule-table__item-text {
        color: var(--Red-Dark-main, #ad0015);
      }
    }

    &.__selected {
      background: var(--Primary-light-Main, #4196f0);
    }
  }

  &__item-text {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  &__left-block,
  &__place-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__date-block {
    display: grid;
    /** TODO - модалка тегов: вернуть repeat(2, 1fr); **/
    grid-template-columns: 1fr;
    gap: 8px;
  }
  &__place-block {
    min-width: -moz-available;
    min-width: -webkit-fill-available;
    min-width: fill-available;
  }

  @include mobile-only {
    &__block {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      gap: var(--sp-1);
    }

    &__date-block {
      grid-template-columns: 1fr;
    }

    &__table {
      gap: var(--sp-ex-1);
      row-gap: var(--sp-1);
    }

    &__cell {
      border-radius: 0;
    }

    &__item {
      &.__header {
        padding: var(--sp-3) 0;
        @include add-font-face("body-3");
      }
    }
  }
}
.d-flex {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
