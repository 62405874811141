import { COLORS_HIGHLIGHT } from "@/config/design";

class colorControl {
  constructor(arr) {
    // list of used colors
    this.CHU = arr;
  }

  getFreeColor() {
    for (const color of COLORS_HIGHLIGHT) {
      const el = this.CHU.find(el => el.color == color);
      if (!el) return color;
    }
    return null;
  }

  setUserColor(id_user, color) {
    this.CHU.push({ id_user: id_user, color: color });
  }

  clearColor(color) {
    const i = this.CHU.findIndex(el => el.color == color);
    if (i == -1) return false;
    this.CHU.splice(i, 1);
    return true;
  }

  getUserColor(id_user) {
    const el = this.CHU.find(el => el.id_user == id_user);
    if (!el) return null;
    return el.color;
  }

  clearAll() {
    this.CHU = [];
  }

  freeColorsCount() {
    return COLORS_HIGHLIGHT.length - this.CHU.length;
  }
}
export { colorControl };
