<template>
  <modal
    name="shift-tags-setup"
    max-width="400px"
    @submit="handleSubmit"
    @hiddenModal="v => $emit('handleCloseModal', v)"
  >
    <template #title="{ payload }">
      <div v-if="payload" class="shift-tags-setup-modal__header">
        <div class="shift-tags-setup-modal__title">Настройка:</div>
        <div class="shift-tags-setup-modal__header-wrapper">
          <div class="shift-tags-setup-modal__title-date">
            <span class="color-neutral-600">{{ getDateWorkShift(payload?.date.date).dayOfWeek }}</span>
            <span>{{ getDateWorkShift(payload?.date.date).dateFormatted }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="shift-tags-setup-modal__content">
        <div class="shift-tags-setup-modal__description">
          Можете изменить название строк или добавить новую строку.
        </div>
        <div class="shift-tags-setup-modal__list">
          <div v-for="(row, index) in rows" class="shift-tags-setup-modal__row" :key="index">
            <input
              class="shift-tags-setup-modal__row-item"
              placeholder="Введите название строки"
              :value="row?.tag"
              @change="v => hag(index, v)"
            />
            <div class="shift-tags-setup-modal__row-item">
              <base-icon
                path="close-blue.svg"
                width="16"
                height="16"
                screen-size-dependency
                @click="deleteRow(index)"
              />
            </div>
          </div>
        </div>

        <base-button is-new tertiary small class="mt-4" @click="addRow">
          <base-icon path="plus.svg" width="16" height="16" screen-size-dependency />
          <span class="ml-ex-10">Добавить строку</span>
        </base-button>

        <div class="mt-6">
          <div class="h4">Повторяемость</div>
          <div class="mt-3">
            <base-input type="radio" name="repeat" value="today" label="Только сегодня" checked />
            <base-input type="radio" name="repeat" value="all" label="Применить к этому и всем последующим" />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script setup>
import Modal from "@/components/Modal/Modal.vue";
import { formattedDate } from "@/components-js/dateFormat";
import { useStore } from "vue2-helpers/vuex";
import { computed, ref, watch } from "vue";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";
import BaseButton from "@/components/BaseButton/BaseButton.vue";
import BaseInput from "@/components/BaseInput/BaseInput.vue";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";

const emits = defineEmits(["updateTags"]);

const store = useStore();
const payload = computed(() => store.state.modalPayload);
const rows = ref(payload.value?.date?.timeData);

const getDateWorkShift = date => {
  return formattedDate(new Date(date));
};

const addRow = () => {
  rows.value?.push({ shiftList: [], tag: "" });
};

const deleteRow = rowIndex => {
  rows.value?.splice(rowIndex, 1);
};

const hag = (index, v) => {
  rows.value[index].tag = v.target.value;
};

const handleSubmit = v => {
  emits("updateTags", { date: payload.value?.date, timeData: rows.value });
  emitter.emit(EVENTS.HIDE_MODAL);
};

watch(payload, newVal => {
  rows.value = newVal?.date?.timeData;
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";

.shift-tags-setup-modal {
  &__header {
    display: flex;
    gap: var(--sp-2);
    padding-top: var(--sp-1);
  }

  &__title {
    @include add-font-face("h4", false, true);
  }

  &__header-wrapper {
    display: flex;
    gap: var(--sp-6);
  }

  &__title-date {
    display: flex;
    gap: var(--sp-1);
    @include add-font-face("body-1", false, true);
  }

  &__additional-info {
    display: flex;
    gap: var(--sp-3);
  }

  &__description {
    margin-top: var(--sp-4);
    @include add-font-face("body-2", var(--color-neutral-600), false, true);
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Cpacing-0-8, 12px);
    align-self: stretch;
    margin-top: 12px;
  }

  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Cpacing-0-6, 8px);
    align-self: stretch;
  }

  &__row-item {
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    background: var(--Primary-bg, #dbedff);
    color: var(--Primary-Main, #0663c7);

    /* Button,Link/Desktop/Small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    text-align: center;

    &::placeholder {
      color: var(--Neutral-400, var(--Calor-Neutral-Gray-400, #94a3b8));
    }

    &:first-child {
      width: 100%;
    }

    &:last-child {
      display: flex;
      width: 40px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: none;
      outline: none;
    }
  }

  @include mobile-only {
    &__header {
      padding-top: 0;
    }
    &__description {
      margin-top: var(--sp-3);
    }
  }
}
</style>
