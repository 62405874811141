<template>
  <div class="action-cell" :class="{ __date: date, __now: isNow }" @click="$emit('openSettings', date)">
    <div class="action-cell__info">
      <template v-if="date">
        <span class="body-3 color-neutral-600">{{ getDateWorkShift(date.date).dayOfWeek }}</span>
        <span class="body-2">{{ getDateWorkShift(date.date).dateFormatted }}</span>
      </template>
      <template v-if="tag">
        {{ tag }}
      </template>
    </div>
    <!-- TODO - модалка тегов: вернуть date когда будем реализовывать модалку по настройки строк и тегов -->
    <base-icon v-if="false" class="action-cell__icon" path="menu-trigger.svg" />
  </div>
</template>

<script setup>
import BaseIcon from "@/components/BaseIcon";
import { dateFormatJS, formattedDate } from "@/components-js/dateFormat";
import { computed } from "vue";

const props = defineProps({
  date: Object,
  tag: Object,
});

const getDateWorkShift = date => {
  return formattedDate(new Date(date));
};

const isNow = computed(() => dateFormatJS(new Date()) == props.date?.date);
</script>

<style scoped lang="scss">
@import "@/styles/helpers";
@import "@/styles/ui/breakPoints";
.action-cell {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 77px;
  height: 100%;
  border-radius: 5px;
  background: var(--Primary-light-bg, #eef5fc);
  overflow: hidden;

  &.__date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: cell;

    .action-cell__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      padding-top: var(--sp-2);
    }
  }

  &.__now {
    background: var(--color-primary-light-main, #0053ad);

    .body-3 {
      color: var(--Primary-bg, #DBEDFF);
    }

    .body-2 {
      color: var(--Basic-White, #FFF);
    }
  }

  &__info {
    padding: var(--Cpacing-0-7, 10px);
    color: var(--Primary-Dark-main, #0053ad);
    @include text-truncate;
  }

  &__icon {
    margin-bottom: var(--sp-2);
  }

  @include mobile-only {
    &.__date {
      flex-direction: row;
      justify-content: center;

      .action-cell__info {
        flex-direction: row;
        gap: var(--sp-1);
        padding: var(--sp-ex-6);
      }
    }

    &__icon {
      position: absolute;
      right: 20px;
      margin-bottom: 0;
    }
  }
}
</style>
