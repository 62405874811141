<template>
  <div class="schedule-page">
    <div v-if="isMobile" class="schedule-page__title h2">{{ pageConfig.title }}</div>
    <table-filters
      class="schedule-page__filter-wrapper"
      can-change-point
      :date-begin="startDay"
      :date-end="endDay"
      :point-id="pointId"
      @submit="handleSubmitFilter"
    />
    <schedule-table
      :work-shift-list="workShiftList"
      :can-add-work-shift="canAddWorkShift"
      :is-loading="loading"
      @updateTags="handleUpdateTag"
      @addNewRow="v => handleAddNewRow(v)"
      @handleRightClickCell="setColorCellsByPeople"
      @updateShift="handleUpdateShift"
    />
  </div>
</template>

<script setup>
import Vue from "vue";
import { DATE_MAX_INTERVAL, HOUR_END, HOUR_START } from "@/config/settings";
import { computed, onMounted, ref } from "vue";
import { dateFormat, dateFormatJS } from "@/components-js/dateFormat";
import { addDays, differenceInDays, endOfMonth, startOfMonth } from "date-fns";
import { useStore } from "vue2-helpers/vuex";
import { request } from "@/components-js/requestSrv";
import { useRoute } from "vue2-helpers/vue-router";
import { lS } from "@/components-js/localStorage";
import { datesCheck } from "@/components-js/datesCheck";
import TableFilters from "@/components/TableFilters";
import ScheduleTable from "./components/ScheduleTable";
import { colorControl } from "@/components-js/colorControl";
import { COLORS_HIGHLIGHT_USAGE } from "@/config/design";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";

const loStorage = new lS();
const pageConfig = {
  title: "Расписание",
  desktopTable: {
    cellWidth: 70,
    cellHeight: 44,
  },
  mobileTable: {
    cellWidth: 70,
    cellHeight: 44,
  },
  dateFormat: "dd.MM.yyyy",
  defaultRowsCount: 2,
};

const checkDate = (date, isEnd) => {
  if (date.getDate() <= 15 && !isEnd) return startOfMonth(date);
  if (date.getDate() > 15 && !isEnd) return addDays(startOfMonth(date), 15);
  if (date.getDate() <= 15 && isEnd) return addDays(startOfMonth(date), 14);
  if (date.getDate() > 15 && isEnd) return endOfMonth(date);
};

const props = defineProps({
  wParam: String,
});

const route = useRoute();
const store = useStore();
/**
 * @type {EmployeeList}
 * */
const employeeList = ref([]);
/**
 * @type {JobList}
 * */
const jobList = ref([]);
/**
 * @type {WorkShiftList}
 * */
const workShiftList = ref([]);
const workShiftListTmp = ref([]);
const sales = ref({});
const startDay = ref("");
const endDay = ref("");
const pointId = ref();
const salesLength = ref(0);
const autoTable = ref(false);
const showFilter = ref(false);
const loading = ref(false);
const salesShow = ref(false);
const canAddWorkShift = ref(false);

const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);
const user = computed(() => store?.state.user);
const isAdminsRole = computed(() => user.value?.roles?.includes("admin"));
const formattedDateRange = computed(() => {
  return `${dateFormat(new Date(startDay.value), pageConfig.dateFormat)} - ${dateFormat(
    new Date(endDay.value),
    pageConfig.dateFormat
  )}`;
});
const showChecks = computed(() => {
  return isAdminsRole.value && sales.value.length && !salesShow.value;
});

const handleUpdateTag = v => {
  const findId = workShiftList.value?.findIndex(item => item.date === v.date.date);
  Vue.set(workShiftList.value, findId, v);
  // workShiftList.value[findId].timeData = v.rows;
};

const handleUpdateShift = async () => {
  await fillCells();
};

const handleAddNewRow = async v => {
  await addRowIntoTable(v);
};

const handleSubmitFilter = async filterData => {
  loading.value = true;
  startDay.value = filterData.dateBegin;
  endDay.value = filterData.dateEnd;
  pointId.value = filterData.pointId;
  await fillCells();
  canAddWorkShift.value = await accessCheck();
  loading.value = false;
};

const correctDatePeriod = () => {
  const { date_begin, date_end } = datesCheck(startDay.value, endDay.value, DATE_MAX_INTERVAL);
  startDay.value = dateFormatJS(date_begin);
  endDay.value = dateFormatJS(date_end);
};

const getEmployeeList = async () => {
  employeeList.value = await request("/api/user/listall", "GET");
};

const getJobList = async () => {
  jobList.value = await request("/api/job/list", "GET");
};

const getScheduleEntryList = async () => {
  let apiUrl = "/api/turn/list";
  if (autoTable.value) apiUrl = "/api/turn/listauto";

  workShiftListTmp.value = await request(
    apiUrl,
    "POST",
    {
      id_point: pointId.value,
      date_begin: startDay.value,
      date_end: endDay.value,
    },
    route.params && route.params.pointId ? null : route.path
  );
};

const getSalesList = async () => {
  sales.value = await request("/api/sales/salesget", "POST", {
    id_point: pointId.value,
    date_begin: startDay.value,
    date_end: endDay.value,
  });
};

const processSalesData = async () => {
  const salesData = await getSalesList();
  if (salesData) {
    sales.value = {};
    salesData.forEach(sale => {
      if (!sales.value[sale.date]) {
        sales.value[sale.date] = [];
      }
      sales.value[sale.date][sale.hour] = sale.sales;
    });
  }
};

const getConditionCheck = (currentWorkShift, checkingWorkShift, isNext) => {
  if (!checkingWorkShift) return;

  const dateMatch = checkingWorkShift.turn_date === currentWorkShift.turn_date;
  const rowIndexMatch = checkingWorkShift.turn_order === currentWorkShift.turn_order;
  const hourMatch = isNext
    ? checkingWorkShift.hour_begin === currentWorkShift.hour_end
    : checkingWorkShift.hour_end === currentWorkShift.hour_begin;

  const minutesMatch = isNext
    ? checkingWorkShift.minute_begin === currentWorkShift.minute_end
    : checkingWorkShift.minute_end === currentWorkShift.minute_begin;

  const diffHours = isNext
    ? currentWorkShift.hour_end < checkingWorkShift.hour_begin
    : currentWorkShift.hour_begin > checkingWorkShift.hour_end;

  const diffMinutes = isNext
    ? currentWorkShift.hour_end === checkingWorkShift.hour_begin &&
      currentWorkShift.minute_end < checkingWorkShift.minute_begin
    : currentWorkShift.hour_begin === checkingWorkShift.hour_end &&
      currentWorkShift.minute_begin > checkingWorkShift.minute_end;

  const workShiftIsCorrect = checkingWorkShift.hour_begin <= checkingWorkShift.hour_end;

  return {
    workShiftIsCorrect,
    dateMatch,
    rowIndexMatch,
    hourMatch,
    minutesMatch,
    diffHours: dateMatch && rowIndexMatch && diffHours,
    diffMinutes: dateMatch && rowIndexMatch && diffMinutes,
  };
};

const fillCells = async () => {
  showFilter.value = false;
  salesShow.value = false;
  salesLength.value = 0;

  correctDatePeriod();
  await Promise.all([getJobList(), getEmployeeList(), processSalesData(), getScheduleEntryList()]);

  workShiftList.value = [];

  const sortingWorkShiftList = workShiftListTmp.value?.sort((a, b) =>
    a.utime_begin < b.utime_begin && a.turn_order < b.turn_order ? -1 : 1
  );

  const checkedWorkShift = [];
  const orderMap = {};
  let currentShiftOrder = -1;
  let count = 0;

  sortingWorkShiftList.forEach(workShift => {
    if (currentShiftOrder !== workShift.turn_order) {
      currentShiftOrder = workShift.turn_order;
      if (Array.isArray(orderMap[workShift.turn_date])) {
        orderMap[workShift.turn_date].push(currentShiftOrder);
      } else {
        orderMap[workShift.turn_date] = [currentShiftOrder];
      }
    }
  });

  //цикл по выбранным в фильтре датам
  for (let i = 0; i <= Math.abs(differenceInDays(new Date(startDay.value), new Date(endDay.value))); i++) {
    const currentDay = dateFormatJS(addDays(new Date(startDay.value), i));

    workShiftList.value.push({
      date: currentDay,
      timeData: [],
    });

    let firstRowIndex = 0;
    let lastRowIndex = pageConfig.defaultRowsCount - 1;

    if (orderMap[currentDay]?.length) {
      firstRowIndex = orderMap[currentDay][0];
      lastRowIndex = orderMap[currentDay][orderMap[currentDay].length - 1];
    }

    if (firstRowIndex > 0) {
      firstRowIndex = 0;
    }

    if (lastRowIndex < pageConfig.defaultRowsCount - 1) {
      lastRowIndex = pageConfig.defaultRowsCount - 1;
    }

    //цикл по строкам в датах, если в дате нет смен отображаем 2 строки
    for (let rowIndex = firstRowIndex; rowIndex <= lastRowIndex; rowIndex++) {
      workShiftList.value[i].timeData.push({
        shiftList: [],
        tag: "",
      });

      //цикл по часам работы
      for (let currentHour = HOUR_START; currentHour < HOUR_END; currentHour++) {
        // поиск смены начинающуюся в проверяемом часе
        const foundedWorkShiftIndex = sortingWorkShiftList.findIndex(
          workShift =>
            currentHour === workShift.hour_begin &&
            currentDay === workShift.turn_date &&
            rowIndex === workShift.turn_order
        );
        const workShiftIsFound = foundedWorkShiftIndex > -1;

        if (workShiftIsFound) {
          let currentWorkShift = sortingWorkShiftList[foundedWorkShiftIndex];
          let prevWorkShift = sortingWorkShiftList[foundedWorkShiftIndex - 1];
          let nextWorkShift = sortingWorkShiftList[foundedWorkShiftIndex + 1];
          const workShiftIsCorrect = workShiftIsFound && currentWorkShift.hour_begin <= currentWorkShift.hour_end;

          let conditionsPrev = getConditionCheck(currentWorkShift, prevWorkShift);
          let conditionsNext = getConditionCheck(currentWorkShift, nextWorkShift, true);
          let skip = true;

          if (checkedWorkShift.includes(currentWorkShift.id_turn)) {
            if (workShiftIsCorrect) {
              currentHour = currentWorkShift.hour_end - 1;

              if (currentWorkShift.minute_begin > 0 || currentWorkShift.minute_end > 0) {
                if (conditionsNext && conditionsNext.workShiftIsCorrect) {
                  if (conditionsNext.dateMatch && conditionsNext.rowIndexMatch) {
                    if (conditionsNext.hourMatch) {
                      prevWorkShift = currentWorkShift;
                      currentWorkShift = nextWorkShift;
                      nextWorkShift = sortingWorkShiftList[foundedWorkShiftIndex + 2];
                      skip = false;

                      conditionsPrev = getConditionCheck(currentWorkShift, prevWorkShift);
                      conditionsNext = getConditionCheck(currentWorkShift, nextWorkShift, true);
                    }
                  }
                }
              }
            }

            if (skip) {
              continue;
            }
          }

          checkedWorkShift.push(currentWorkShift.id_turn);

          if (workShiftIsCorrect) {
            currentHour = currentWorkShift.hour_end - 1;
          }

          if (currentWorkShift.minute_begin > 0) {
            if (conditionsPrev && conditionsPrev.workShiftIsCorrect) {
              if (conditionsPrev.dateMatch && conditionsPrev.rowIndexMatch) {
                if (conditionsPrev.hourMatch) {
                  if (conditionsPrev.minutesMatch) {
                    // ничего
                  } else if (currentWorkShift.minute_begin > prevWorkShift.minute_end) {
                    // ячейку между сменами

                    workShiftList.value[i].timeData[rowIndex].shiftList.push({
                      id: null,
                      pointId: pointId.value,
                      date: currentDay,
                      hourBegin: currentWorkShift.hour_begin,
                      hourEnd: currentWorkShift.hour_begin,
                      minuteBegin: prevWorkShift.minute_end,
                      minuteEnd: currentWorkShift.minute_begin,
                    });
                  }
                } else {
                  workShiftList.value[i].timeData[rowIndex].shiftList.push({
                    id: null,
                    pointId: pointId.value,
                    date: currentDay,
                    hourBegin: currentWorkShift.hour_begin,
                    hourEnd: currentWorkShift.hour_begin,
                    minuteBegin: 0,
                    minuteEnd: currentWorkShift.minute_begin,
                  });
                }
              } else {
                workShiftList.value[i].timeData[rowIndex].shiftList.push({
                  id: null,
                  pointId: pointId.value,
                  date: currentDay,
                  hourBegin: currentWorkShift.hour_begin,
                  hourEnd: currentWorkShift.hour_begin,
                  minuteBegin: 0,
                  minuteEnd: currentWorkShift.minute_begin,
                });
              }
            } else {
              workShiftList.value[i].timeData[rowIndex].shiftList.push({
                id: null,
                pointId: pointId.value,
                date: currentDay,
                hourBegin: currentWorkShift.hour_begin,
                hourEnd: currentWorkShift.hour_begin,
                minuteBegin: 0,
                minuteEnd: currentWorkShift.minute_begin,
              });
            }
          }

          //добавляем ячейку со сменой
          workShiftList.value[i].timeData[rowIndex].shiftList.push({
            id: currentWorkShift.id_turn,
            pointId: currentWorkShift.id_point,
            employee: employeeList.value?.find(employee => employee.id === currentWorkShift.id_people),
            job: jobList.value?.find(job => Number(job.id) === Number(currentWorkShift.id_job)),
            date: currentWorkShift.turn_date,
            hourBegin: currentWorkShift.hour_begin,
            hourEnd: currentWorkShift.hour_end,
            minuteBegin: currentWorkShift.minute_begin,
            minuteEnd: currentWorkShift.minute_end,
            timeAdd: currentWorkShift.time_add,
            timeAddNote: currentWorkShift.time_add_note,
            turnOrder: currentWorkShift.turn_order,
            highlight: null,
            status: currentWorkShift.status,
          });

          if (currentWorkShift.minute_end > 0) {
            if (conditionsNext) {
              if (conditionsNext.workShiftIsCorrect && conditionsNext.dateMatch && conditionsNext.rowIndexMatch) {
                if (conditionsNext.hourMatch) {
                  // currentHour++;
                }
              }
            }

            // если это последняя смена или час начала следующей смены не совпадает с часом окончания текущей
            if (!conditionsNext || (conditionsNext && conditionsNext.workShiftIsCorrect && !conditionsNext.hourMatch)) {
              // добавляем пустую ячейку от минут окончания текущей смены до начала нового часа
              workShiftList.value[i].timeData[rowIndex].shiftList.push({
                id: null,
                pointId: pointId.value,
                date: currentDay,
                hourBegin: currentWorkShift.hour_end,
                hourEnd: currentWorkShift.hour_end + 1,
                minuteBegin: currentWorkShift.minute_end,
                minuteEnd: 0,
              });
              currentHour++;
            }
          }

          if (!workShiftIsCorrect) {
            const people = employeeList.value?.find(employee => employee.id === currentWorkShift.id_people);

            console.error("Ошибка в смене", {
              message: "Время окончания смены меньше чем время её начала",
              workShift: {
                id: currentWorkShift.id_turn,
                date: currentWorkShift.turn_date,
                hourBegin: currentWorkShift.hour_begin,
                hourEnd: currentWorkShift.hour_end,
                minuteBegin: currentWorkShift.minute_begin,
                minuteEnd: currentWorkShift.minute_end,
                people: {
                  name: people.surname + " " + people.name,
                  nick: people.nickname,
                  job: people.job_name,
                },
              },
            });
          }
        } else {
          //добавляем пустую ячейку
          workShiftList.value[i].timeData[rowIndex].shiftList.push({
            id: null,
            pointId: pointId.value,
            date: currentDay,
            hourBegin: currentHour,
            hourEnd: currentHour + 1,
            minuteBegin: 0,
            minuteEnd: 0,
          });
        }
      }
    }
  }
};

const getCachedData = paramName => {
  if (route.params && route.params[paramName]) {
    return route.params[paramName];
  } else {
    return loStorage.getObjectProp(route.path, paramName);
  }
};

const setColorCellsByPeople = workShift => {
  const CC = new colorControl(COLORS_HIGHLIGHT_USAGE);
  const employeeId = workShift.employee.id;
  const colorUs = CC.getUserColor(employeeId);
  const color = CC.getFreeColor();
  let a;

  for (let i = 0; i < workShiftList.value?.length; i++) {
    const date = workShiftList.value[i].timeData;

    for (let j = 0; j < date?.length; j++) {
      const row = date[j].shiftList;

      for (let k = 0; k < row?.length; k++) {
        const workShift = row[k];

        if (workShift && workShift.employee?.id === employeeId) {
          if (colorUs) {
            CC.clearColor(colorUs);
            a = null;
          } else {
            if (color) {
              CC.setUserColor(employeeId, color);
            }
            a = color;
          }
          workShiftList.value[i].timeData[j].shiftList[k].highlight = a;
        }
      }
    }
  }
};

const accessCheck = async () => {
  // if Admin let him do anything
  if (user.value?.roles.includes("admin")) return true;
  else {
    // not PointAdmin
    if (!user.value?.roles.includes("pointadmin")) return false;
    // check current point
    const res = await request("/api/user/checkpointaccess", "POST", {
      uid: user.value?.uid,
      id: pointId.value,
    });

    return !!parseInt(res.cnt);
  }
};

const addRowIntoTable = async date => {
  // roles access
  const check = await accessCheck();
  if (!check) return;

  const test = workShiftList.value.find(item => {
    return item.date == date.date;
  });

  const shiftList = [];
  for (let i = HOUR_START; i < HOUR_END; i++) {
    shiftList.push({
      id: null,
      pointId: pointId.value,
      date: date.date,
      hourBegin: i,
      hourEnd: i + 1,
      minuteBegin: 0,
      minuteEnd: 0,
    });
  }
  test.timeData.push({ shiftList, tag: "" });
};

const setDataInRouteParams = () => {
  let cachedData = getCachedData("date_begin");
  startDay.value = dateFormatJS(cachedData ? cachedData : checkDate(new Date()));

  cachedData = getCachedData("date_end");
  endDay.value = dateFormatJS(cachedData ? cachedData : checkDate(new Date(), true));

  cachedData = getCachedData("id_point");
  if (cachedData) pointId.value = Number(cachedData);
};
setDataInRouteParams();

onMounted(async () => {
  pointId.value = pointId.value ?? store?.state.pointList[0]?.id;
  await fillCells();
  canAddWorkShift.value = await accessCheck();
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/breakPoints";

.schedule-page {
  padding: var(--sp-8) var(--sp-11);
  padding-top: 0;

  &__filter-wrapper {
    position: sticky;
    top: 0;
    padding-bottom: var(--sp-6);
    background: var(--color-basic-white);
    z-index: 1;

    @include mobile-up {
      padding-top: var(--sp-8);
      top: 0;
    }

    @include mobile-only {
      top: 44px;
      padding-top: var(--sp-4);
      padding-bottom: 24px;
    }
  }
}

.flex-row {
  display: grid;
  //grid-template-columns: 1.1fr 9fr;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 8px;
  width: 100%;
  max-width: 1490px;

  &.__one-column {
    grid-template-columns: 1fr;
  }
}

.schedule-page {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__cell {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;

    &.__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__item {
    display: flex;
    height: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Primary-light-bg, #eef5fc);

    &.__date {
      height: 100%;
      justify-content: flex-start;
      padding: var(--sp-2) 0;
    }

    &.__header {
      background: var(--Secondary-Bg, #ffd596);
    }
  }

  &__item-text {
    background: var(--Primary-bg, #dbedff);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__left-block,
  &__place-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__date-block {
    display: flex;
    gap: 8px;
  }

  &__place-block {
    width: 100%;
  }

  @include mobile-only {
    padding: 0;

    &__title {
      padding: var(--sp-6) var(--sp-4) var(--sp-4);
      padding-bottom: 0;
      background: var(--color-basic-white);
    }
  }
}
</style>
